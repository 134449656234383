import * as React from "react"
import { Link } from "gatsby"

import Layout from "../../../templates/layout-standard"
import Seo from "../../../components/seo"
import SideBar from '../../../components/sidebar';
import Turning65Nav from '../../../components/nav/Turning65';
import UpNextCardNonStrapi from '../../../components/ui/UpNextCardNonStrapi';
import LearnMore from '../../../components/learnmore';
import SpeakWithAdvisor from '../../../components/ui/SpeakWithAdvisor';

const Turning65Start = ({ location }) => (
	<Layout location={location} heading1="Turning 65" heading2="Let&rsquo;s get you ready for Medicare.">
    	<Seo title="Where To Start | Turning 65 | Medicare Basics" meta={[{description: 'Learn how to make the most of the various Medicare plan options available to you.'}]} bodyclass="medicare-basics section1 subpage" />
	    <Turning65Nav />
			
		<div className="constrained">
			<div className="content-constrained">
				<div className="flexwrap_subpage">

					<main>
						<h3>Where to Start for Medicare</h3>
						
						<p>There is nothing more important than your health. UVM Health Advantage understands this. If you are approaching or about to turn 65, it&rsquo;s important to understand your health care choices so you can ensure you have the right coverage for your lifestyle.</p>
							
						<p>Use the links above for a simple overview of Medicare, as well as helpful guides and tips, to help you be ready when it&rsquo;s time to enroll.</p>
							
						<p>Let&rsquo;s take it step by step.</p>

						<UpNextCardNonStrapi to="/medicare-basics/turning-65/learn-how-medicare-works/">Learn How Medicare Works</UpNextCardNonStrapi>
						<LearnMore />
						<SpeakWithAdvisor />
					</main>
					
					<SideBar location={location} />
				</div>
			</div>
		</div>
	</Layout>
)

export default Turning65Start
